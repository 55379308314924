import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";
import {store as paymentStore} from "./store";

serverLogPlugin(log, "payment");
log.setLevel("warn");
configureStoreForAuthentication(paymentStore);
configureEmotionCache("payment");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

export const DepositModal = () =>
    import(/* webpackChunkName: "Modal" */ "./components/DepositModal");

export const VerifyContactInformationModal = () =>
    import(
        /* webpackChunkName: "VerifyContactInformationModal" */ "./components/VerifyContactInformationModal"
    );

export const UpdateContactInformationModal = () =>
    import(
        /* webpackChunkName: "UpdateContactInformationModal" */ "./components/UpdateContactInformationModal"
    );
