import root from "window-or-global";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {
    USER_SERVICE_URL,
    SUBMAN_SERVICE_URL,
    MEMBER_SERVICE_V4_URL,
    BALANCE_V3_URL,
} from "@atg-shared/service-url";
import type {AtgResponse} from "@atg-shared/fetch-types";
import type {
    FetchUserResponse,
    FetchBalanceResponse,
    FetchContactOptionsResponse,
} from "./user.types";
import type {LogoutOptions, FetchBalanceOptions} from "./userActions";

const LOGIN_URL = `${USER_SERVICE_URL}/login`;
const LOGOUT_URL = `${USER_SERVICE_URL}/logout`;

export const CONTACT_OPTIONS_CUSTOMER_MODAL = `${MEMBER_SERVICE_V4_URL}/customermodal`;

export type ReduceScopeResponse = {
    accessToken: string;
};

export function login(params: Record<string, any>) {
    return atgRequest(LOGIN_URL, {
        method: "POST",
        body: JSON.stringify(params),
    });
}

const fetchUser = (accessToken?: string): Promise<AtgResponse<FetchUserResponse>> =>
    atgRequest(MEMBER_SERVICE_V4_URL, undefined, accessToken);

const fetchAuthorizedUser = (): Promise<AtgResponse<FetchUserResponse>> =>
    fetchAuthorized(MEMBER_SERVICE_V4_URL);

const fetchUserContactOptionsCustomer = (): Promise<
    AtgResponse<FetchContactOptionsResponse>
> => fetchAuthorized(CONTACT_OPTIONS_CUSTOMER_MODAL);

const fetchBalance = ({
    requireAuth = true,
}: FetchBalanceOptions): Promise<AtgResponse<FetchBalanceResponse>> =>
    requireAuth ? fetchAuthorized(BALANCE_V3_URL) : atgRequest(BALANCE_V3_URL);

export {fetchUser, fetchAuthorizedUser, fetchUserContactOptionsCustomer, fetchBalance};

export const logout = async (options: LogoutOptions) => {
    atgRequest(
        `${options.logoutUrl ? options.logoutUrl : LOGOUT_URL}${
            options.soft ? "?soft=true" : ""
        }`,
        {
            method: "POST",
            body: JSON.stringify({}),
        },
    );
};

// "simple" request to allow cross origin between atg and tillsammans without any server config change
export const tillsammansLogout = () => {
    const logoutUrl = root.clientConfig.curity.tillsammansWicketLogoutURI;

    if (!logoutUrl) {
        return Promise.reject(
            new Error(
                "tillsammansWicketLogoutURI is missing in client config, unable to perform Tillsammans logout",
            ),
        );
    }

    return pureFetch<void>(logoutUrl, {
        method: "POST",
        credentials: "include",
    });
};

export const reduceScope = (): Promise<AtgResponse<ReduceScopeResponse>> =>
    pureFetch(`${root.clientConfig.curity.tokenHandlerURI}/utils/reduce-scope`, {
        method: "GET",
        credentials: "include",
    });

export const subscribeUser = (clientName: string, route: string) =>
    fetchAuthorized(`${SUBMAN_SERVICE_URL}/subscriptions/personal`, {
        body: JSON.stringify({
            clientName,
            route,
        }),
        method: "POST",
    });
