import {put, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as ModalActions from "atg-modals/modalActions";
import * as AvatarActions from "./avatarActions";

export function* saveAvatar(action: AvatarActions.SaveAvatarAction): SagaIterator {
    if (action.type !== AvatarActions.AVATAR_SAVE_RESPONSE) return;
    if (action.error) return;

    yield put(UserActions.updateUserAvatar(action.payload.imageUrl));
    yield put(ModalActions.closeImageUploadModal());
    yield put(AvatarActions.resetSaveAvatar());
}

export function* removeAvatar(action: AvatarActions.RemoveAvatarAction): SagaIterator {
    if ("error" in action && action.error) return;

    yield put(UserActions.removeUserAvatar());
    yield put(ModalActions.closeRemoveAvatarModal());
    yield put(AvatarActions.resetRemoveAvatar());
}

export function* avatarSaga(): SagaIterator {
    yield takeLatest(AvatarActions.AVATAR_SAVE_RESPONSE, saveAvatar);
    yield takeLatest(AvatarActions.AVATAR_REMOVE_RESPONSE, removeAvatar);
}
