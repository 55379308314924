import type {SagaIterator} from "redux-saga";
import {call, put, select, takeLatest} from "redux-saga/effects";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as ModalActions from "atg-modals/modalActions";
import * as ModalSelectors from "atg-modals/modalSelectors";
import * as ContactInfoActions from "./contactInfoActions";

export function* closeModal(): SagaIterator {
    const isEditMobileModalOpen = yield select(
        ModalSelectors.isAccountSettingsMobileModalOpen,
    );
    if (isEditMobileModalOpen) {
        yield put(ModalActions.closeEditMobileNumberModal());
        return;
    }

    const isEditEmailModalOpen = yield select(
        ModalSelectors.isAccountSettingsEmailModalOpen,
    );
    if (isEditEmailModalOpen) {
        yield put(ModalActions.closeEditEmailModal());
    }
}

export function* editContactInfoFlow(action: ContactInfoActions.SetContactInfoAction) {
    if ("error" in action && action.error) return;

    yield put(UserActions.fetchUser());

    yield call(closeModal);
}

export function* contactInfoSaga() {
    yield takeLatest(ContactInfoActions.SET_CONTACT_INFO_RESPONSE, editContactInfoFlow);
}
