import type {SagaIterator} from "redux-saga";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import {closeSmsRecoveryModal} from "atg-modals/modalActions";
import * as ModalActions from "atg-modals/modalActions";
import * as SmsRecoveryActions from "./smsRecoveryActions";
import {clearCache} from "./smsRecoveryApi";
import * as SmsRecoverySelectors from "./smsRecoverySelectors";

export function* closeSmsRecoveryModalFlow(
    action:
        | SmsRecoveryActions.DeleteSmsRecoveryAction
        | SmsRecoveryActions.SaveSmsRecoveryAction,
): SagaIterator {
    if ("error" in action && action.error) return;

    yield put(closeSmsRecoveryModal());
}

function* resetSmsRecoveryStatus(action: ModalActions.Action): SagaIterator {
    if (action.payload !== "smsRecoveryModal") return;

    const hasDeleteError = yield select(SmsRecoverySelectors.hasDeleteError);
    const isDeleteLoaded = yield select(SmsRecoverySelectors.isDeleteLoaded);
    if (hasDeleteError || isDeleteLoaded) {
        yield put(SmsRecoveryActions.resetDeleteAnswerStatus());
    }

    const hasSaveError = yield select(SmsRecoverySelectors.hasSaveError);
    const isSaveLoaded = yield select(SmsRecoverySelectors.isSaveLoaded);
    if (hasSaveError || isSaveLoaded) {
        yield put(SmsRecoveryActions.resetSaveAnswerStatus());
    }
}

export function* smsRecoverySaga(): SagaIterator {
    yield takeLatest(ModalActions.closeSmsRecoveryModal, resetSmsRecoveryStatus);
    yield takeLatest(
        [
            SmsRecoveryActions.DELETE_SMS_RECOVERY_RESPONSE,
            SmsRecoveryActions.SAVE_SMS_RECOVERY_RESPONSE,
        ],
        closeSmsRecoveryModalFlow,
    );

    yield takeLatest(LOGOUT_FINISHED, function* clearCacheOnLogout() {
        yield call(clearCache);
    });
}
