import {combineReducers} from "redux";
import {paymentReducer as payment} from "@atg-payment-shared/money-page-domain";
import {accountSettingsReducer as accountSettings} from "@atg-payment-shared/account-settings-domain";
import {modals} from "atg-modals/modalReducer";
import type {PaymentAction, PaymentStoreState} from "./storeTypes";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<PaymentStoreState, PaymentAction>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        alert: () => window._frameStore.getState().alert,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        modals,
        modalData: () => window._frameStore.getState().modalData,
        payment,
        limits: () => window._frameStore.getState().limits,
        verticals: () => window._frameStore.getState().verticals,
        accountSettings,
    });

export default createRootReducer;
